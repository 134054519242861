<template>
  <div>
    <mood
        id="step1" class="step"
        v-show="steps.includes(1)"
        :chosenMood="selected.mood">
    </mood>
    <guns-or-roses
        id="step2" class="step"
        v-show="steps.includes(2)">
    </guns-or-roses>
    <clusters
        id="step3" class="step"
        :selectedClusters="selected.clusters"
        v-show="steps.includes(3)">
    </clusters>
    <artists
        id="step4" class="step"
        v-show="steps.includes(4)"
        :artistsSelected="selected.artists">
    </artists>
    <generate
        id="step5" class="step"
        v-show="steps.includes(5)"
        :generating="generating">
    </generate>
    <progress-bar :steps="steps"></progress-bar>
  </div>
</template>

<script>
  import Mood from './Mood.vue'
  import GunsOrRoses from './GunsOrRoses.vue'
  import Clusters from './Clusters.vue'
  import Artists from './Artists.vue'
  import Generate from './Generate.vue'
  import ProgressBar from './ProgressBar.vue'
  import { scrollTo } from '../utils/scroll-to'
  import { EventBus } from '../event-bus'
  import {
    SCROLL_OFFSET_TOP, TOTAL_STEPS,
  } from '../config'
  import {
    generatePlaylist, getStats, getProfile,
  } from '../utils/rest'
  import {
    moodSelected,
    clusterSelected,
    clusterRemoved,
    seedArtistAdded,
    seedArtistRemoved,
    skipClicked,
    generatePlaylistClicked,
  } from '../utils/tracker'

  export default {
    name: 'page-generate',
    components: {
      Mood,
      GunsOrRoses,
      Clusters,
      Artists,
      Generate,
      ProgressBar,
    },
    props: {
      selected: {
        type: Object,
      },
      steps: {
        type: Array,
      },
      result: {
        type: Object,
      },
      token: {
        type: String,
      },
      generated: {
        type: Boolean,
      },
      showFacts: {
        type: Boolean,
      },
      factType: {
        type: String,
      },
      fact: {
        type: Object,
      },
    },
    data() {
      return {
        generating: false,
      }
    },
    created() {
      EventBus.$on('nextStep', this.nextStep)
      EventBus.$on('setMood', this.setMood)
      EventBus.$on('setName', this.setName)
      EventBus.$on('toggleArtist', this.toggleArtist)
      EventBus.$on('toggleCluster', this.toggleCluster)
      EventBus.$on('generatePlaylist', this.generatePlaylist)
    },
    mounted() {
      if (this.steps.length > 1) {
        window.location = `${window.location.origin + window.location.pathname}#step${this.steps.length}`
      }
    },
    methods: {
      setMood(id) {
        this.selected.mood = id
        moodSelected(id)
      },
      setName(string) {
        this.selected.name = string
      },
      toggleCluster(cluster) {
        const index = this.selected.clusters.indexOf(cluster)
        if (index >= 0) {
          this.selected.clusters.splice(index, 1)
          clusterRemoved(cluster)
          return
        }

        this.selected.clusters.push(cluster)
        clusterSelected(cluster)
      },
      toggleArtist(artist) {
        const index = this.selected.artists.findIndex((a) => a.spotify_id === artist.spotify_id)
        if (index >= 0) {
          this.selected.artists.splice(index, 1)
          seedArtistRemoved(artist.name)
          return
        }

        this.selected.artists.push(artist)
        seedArtistAdded(artist.name)
      },
      nextStep(step, skipped = false) {
        // add all steps if is last step
        if (step === TOTAL_STEPS && this.steps.length < TOTAL_STEPS) {
          this.steps.length = 0

          for (let i = 1; i < TOTAL_STEPS; i += 1) {
            this.steps.push(i)
          }
        }

        if (!this.steps.includes(step)) {
          this.steps.push(step)
        }

        if (skipped) {
          skipClicked(step)
        }

        this.$nextTick(() => {
          scrollTo(0, this.$el.querySelector(`#step${step}`).offsetTop - SCROLL_OFFSET_TOP)
        })
      },
      async generatePlaylist() {
        if (this.generating) return
        // if nmae is not set fallback name
        if (!this.selected.name) {
          const moodTitle = this.$t(`mood.${this.selected.mood}`).toUpperCase()
          this.selected.name = `Welcome to the ${moodTitle} playlist`
        }

        this.generating = true
        const artistIds = this.selected.artists.map((a) => a.spotify_id)
        const { name, mood, clusters } = this.selected
        let stats = null
        let profile = null

        // track code
        generatePlaylistClicked(name, artistIds, clusters)

        // generate playlist
        const playlist = await generatePlaylist(clusters, mood, name, artistIds, this.token)

        // if token is set get stas and profile
        if (this.token) {
          stats = await getStats(this.token)
          profile = await getProfile(this.token)
        }

        // set result
        this.result.playlist = playlist
        this.result.stats = stats
        this.result.profile = profile

        // set params
        this.generating = false
        EventBus.$emit('setGenerated', true)
      },
    },
  }

</script>

<style lang="scss">
  @import "../scss/setup";

  .step {
    padding: $spacing * 6 0 ($spacing * 20);
    background: url('../assets/images/background.png');
    background-size: cover;
    border-bottom: 1px solid #818182;
    margin-bottom: 2px;
    background-position: center;

    @media screen and (max-width: $media__table-portrait) {
      background-size: contain;
    }
  }
</style>
