import skullBalled from '../assets/images/skull-ballad-colour.png'
import skullEcstatic from '../assets/images/skull-ecstatic-colour.png'
import skullLive from '../assets/images/skull-live-colour.png'
import skullRage from '../assets/images/skull-rage-colour.png'
import skullRock from '../assets/images/skull-rock-colour.png'

import skullBlackBalled from '../assets/images/skull-ballad-bw.png'
import skullBlackEcstatic from '../assets/images/skull-ecstatic-bw.png'
import skullBlackLive from '../assets/images/skull-live-bw.png'
import skullBlackRage from '../assets/images/skull-rage-bw.png'
import skullBlackRock from '../assets/images/skull-rock-bw.png'

const imageMoodMap = {
  rage: [skullRage, skullBlackRage],
  psyched: [skullEcstatic, skullBlackEcstatic],
  ballad: [skullBalled, skullBlackBalled],
  rock_n_roll: [skullRock, skullBlackRock],
  live: [skullLive, skullBlackLive],
}

export default {
  methods: {
    $imageArtist(artist) {
      return artist.images && artist.images.length ? artist.images[0].url : null
    },

    $imageMoodMap(mood, showColor) {
      return imageMoodMap[mood][showColor ? 0 : 1]
    },
  },
}
