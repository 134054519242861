<template>
  <div class="web">
    <div class="web__inner">
      <strong>{{$t('web.expore')}}</strong>
      <div class="web__title-bg"></div>
      <h2 class="web__title corvinus">{{$t('web.' + tab + '.title')}}</h2>
      <button @click="toggleCluster" type="button" class="web__button button button--small"
              :class="{ 'is--selected': isSelected }">
        {{ isSelected ? $t('web.added') : $t('web.addAll')}}
      </button>
    </div>
    <figure class="web__circle" v-for="(artist, index) in artists"
            :key="index" :id="figureId(index)">
      <img class="image-circle image-circle--shadow" v-if="showImage(index, artist)"
           :src="$imageArtist(artist)" :alt="artist.name">
      <i v-else></i>
      <strong :class="{ small: !showImage(index, artist) }">
        {{artist.name}}
      </strong>
    </figure>
  </div>

</template>

<script>
  import { EventBus } from '../event-bus'
  import { WEB_YELLOW_DOTS } from '../config'

  export default {
    name: 'web',
    props: {
      tab: {
        type: String,
      },
      artists: {
        type: Array,
      },
      selectedClusters: {
        type: Array,
      },
    },
    methods: {
      toggleCluster() {
        EventBus.$emit('toggleCluster', this.tab)
      },
      figureId(index) {
        return `circle-${index + 1}`
      },
      showImage(index, artist) {
        return !!this.$imageArtist(artist) && !WEB_YELLOW_DOTS.includes(index)
      },
    },
    computed: {
      isSelected() {
        return this.selectedClusters.includes(this.tab)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../scss/setup";

  .is--selected {
    background: #514D4D;
    color: $color__white;
    // padding: 11px 30px 10px 37px;

    // &:after {
    //   content: '';
    //   position: absolute;
    //   left: 4px;
    //   width: 17px;
    //   height: 17px;
    //   margin: 4px;
    //   bottom: 7px;
    //   background: url("../assets/images/check__dark.svg");
    // }
  }

</style>
