<template>
  <section>
    <step-line :number="1"></step-line>
    <h2 class="ribbon-title">
      <span>{{$t('mood.title')}}</span>
    </h2>
    <p>{{$t('mood.paragraph')}}</p>
    <div class="mood__wrapper">
      <div v-for="mood in moods" :key="mood.id" class="mood">
        <mood-head
            :mood="mood"
            :image="$imageMoodMap(mood, isColorShown(mood))"
            :chosenMood="chosenMood">
        </mood-head>
      </div>
    </div>
    <slick class="mood__carousel" ref="slick" :options="slickOptions"
           @afterChange="setCurrentPage">
      <div v-for="mood in moods" :key="mood.id" class="mood">
        <mood-head
            :mood="mood"
            :image="$imageMoodMap(mood, isColorShown(mood))"
            :chosenMood="chosenMood">
        </mood-head>
      </div>
    </slick>
    <span class="mood__carousel-page">{{currentSlide}}/5</span>
  </section>
</template>

<script>
  import 'slick-carousel/slick/slick.css'
  import Slick from '../vendor/slickCarousel.vue'
  import StepLine from './StepLine.vue'
  import MoodHead from './MoodHead.vue'
  import { MOODS, SLICK_OPTIONS } from '../config'

  export default {
    name: 'mood',
    components: { StepLine, Slick, MoodHead },
    props: {
      chosenMood: {
        type: String,
      },
    },
    data() {
      return {
        moods: MOODS,
        slickOptions: SLICK_OPTIONS,
        currentSlide: 1,
      }
    },
    methods: {
      setCurrentPage(event, slick, currentSlide) {
        this.currentSlide = currentSlide + 1
      },
      isColorShown(mood) {
        if (this.chosenMood === '') {
          return true
        }

        return mood === this.chosenMood
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../scss/setup";
  @import "../scss/ribbon";

  ::v-deep .mood {
    &__ {
      &head {
        margin: 0;
        cursor: pointer;
        padding: 10px;

        @media screen and (max-width: $media__table-portrait) {
          width: 220px;
        }
      }
      &wrapper {
        display: block;
        max-width: 1305px;
        margin: 0 auto;

        @media screen and (max-width: $media__table-portrait) {
          display: none;
        }

        .mood {
          display: inline-block;
          width: 20%;
        }
      }
      &carousel {
        display: none;
        position: relative;

        @media screen and (max-width: $media__table-portrait) {
          display: block;
        }
      }
      &carousel-page {
        display: block;
        margin-top: $spacing;
        color: $color__grey;
        font-size: 11px;
        letter-spacing: $spacing__letter--bigger;

        @media screen and (min-width: $media__mobile) {
          display: none;
        }
      }
      &nav {
        width: 65px;
        height: 65px;
        background: $color__white;
        box-shadow: 2px 3px 11px rgba(0, 0, 0, 0.58), -2px -2px 6px rgba(255, 255, 255, 0.4);
        display: block;
        border-radius: 50%;
        position: absolute;
        bottom: 32px;
        cursor: pointer;

        &:before {
          content: '';
          background: url('../assets/images/arrow__right.svg') no-repeat;
          background-position: center center;
          z-index: 1123;
          height: 51px;
          width: 51px;
          position: absolute;
          text-align: center;
          left: 6px;
          top: 6px;
          border: 1px solid black;
          border-radius: 50%;
        }

        &-- {
          &prev {
            left: $spacing * 2;

            &:before {
              transform: rotate(180deg);
            }
          }
          &next {
            right: $spacing * 2;
          }
        }
      }
    }
  }

  ::v-deep .slick-slide.slick-active {
    h5 {
      opacity: 1;
    }
  }

</style>
