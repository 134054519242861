export default {
  artists: {
    title: 'Künstler hinzufügen',
    paragraph: 'Füge weitere Künstler zu deiner Playlist hinzu.',
    placeholder: 'Hier tippen ...',
    next: 'Weiter',
  },
  ecard: {
    areYou: 'Are You',
    didYouKnow: 'Schon gewusst?',
    fact: 'Fact',
    generateOwn: 'Erstelle deine ganz persönliche Guns N\' Roses Playlist:',
    guns: 'Guns',
    hashtag: '#appetitefordestruction',
    influence: 'Influence',
    is: 'is',
    member: 'Projekte',
    orAre: 'or are',
    percentGuns: '{0}% Guns',
    percentRoses: '{0}% Roses',
    url: 'www.gunsnrosesplaylists.com',
    youRoses: 'you Roses?',
  },
  footer: {
    copyright: '2020 UNIVERSAL MUSIC GMBH. ALLE RECHTE VORBEHALTEN',
    email: 'Deine E-Mail Adresse',
    imprint: 'Impressum',
    imprintLink: 'https://www.universal-music.de/rechtliche-hinweise/impressum',
    privacy: 'Datenschutz',
    privacyLink: 'https://www.universal-music.de/rechtliche-hinweise/datenschutz',
    subscribe: 'Abonnieren',
    subscribeDone: 'Danke für deine Newsletter Anmeldung!',
    terms: 'Du möchtest unsere Guns \'N Roses Newsletter abonnieren. <br>Erhalte per E-Mail immer die neuesten Informationen zu Releases, Gewinnspielen und Aktionen. Du kannst Deine Einwilligung jederzeit widerrufen. Mehr Informationen unter <a href="https://www.universal-music.de/rechtliche-hinweise/sicherheit-und-datenschutz" target="_blank">Sicherheit & Datenschutz.</a>',
    title: 'Abonniere den Guns N\'Roses Newsletter',
  },
  generate: {
    paragraph: 'Geschafft! Klicke den Button und lehne dich zurück, während wir die perfekte Playlist für dich generieren. Are you Guns or are you Roses?',
    title: 'Alles klar!',
    placeholder: 'Playlist Name',
    button: 'Playlist Generieren',
  },
  gunsOrRoses: {
    connectYourSpotify: 'mit Spotify verbinden',
    guns: 'Guns',
    or: 'or',
    paragraph: 'Verbinde deinen Spotify-Account und lass dein Hörverhalten entscheiden: Are you Guns or are you Roses? Deine Top-Artists werden mit Guns N’ Roses Songs gemixt.',
    roses: 'Roses',
    skip: 'Schritt überspringen',
    title: 'Are you Guns or Roses?',
  },
  header: {
    title: 'Welcome to the playlists',
    subtitle: 'ERSTELLE DEINE PERSÖNLICHEN GUNS N\' ROSES PLAYLISTEN',
    secondTitle: 'Are you <span>guns</span> or <span>roses</span>?',
  },
  modal: {
    title: 'Warum mit Spotify verbinden?',
    paragraph: 'Wenn du dich mit deinem Spotify-Account verbindest, können wir herausfinden, ob du mehr auf Upbeat stehst oder entspanntere Musik bevorzugst. Und wir sagen dir: Are you Guns or are you Roses?',
    terms: 'Mehr Informationen zu Social Connect und dem Umgang mit Deinen Daten unter <a href="https://www.universal-music.de/rechtliche-hinweise/sicherheit-und-datenschutz" target="_blank">Sicherheit und Datenschutz</a>.',
    connect: 'Jetzt verbinden',
    abort: 'Abbrechen',
  },
  mood: {
    title: 'WÄHLE DEINE STIMMUNG',
    paragraph: 'Entdecke den Sound von Guns N\' Roses. Wähle die Stimmung deiner Playlist durch das Klicken auf den passenden Skull.',
    rage: 'Rage',
    psyched: 'Ecstatic',
    ballad: 'Ballad',
    rock_n_roll: 'Rock n\' Roll',
    live: 'Live',
    select: 'Wählen',
  },
  overview: {
    addedArtists: 'Manuell hinzugefügt: ',
    basedOn: 'Basierend auf: ',
    connected: 'Verbunden mit: ',
    disconnect: 'trennen',
    namedIt: 'Deine Playlist heißt: ',
    none: 'Nichts',
    notConnected: 'Nicht verbunden',
    selected: 'Ausgewählt: ',
    title: 'Zusammenfassung',
    you: '',
  },
  media: {
    shopUrl: 'https://lnk.to/AppetiteForDestructionWe/gnrstore',
  },
  line: {
    step: 'Schritt',
  },
  progressBar: {
    button: 'Playlist Generieren',
    buttonMobile: 'Generieren',
    complete: 'geschafft',
    skip: 'überspringen',
    stepOf: 'Schritt {0}/{1}',
  },
  result: {
    buy: 'zum Shop',
    creditFacts: '© Credit Fakten: Christof Leim',
    openInSpotify: 'in Spotify öffnen',
    shop: 'Shop',
    shopAlbum: 'Hol‘ Dir Greatest Hits - Jetzt erstmalig auf Vinyl!',
    restart: 'erstelle eine weitere Playlist',
    yourEcard: 'deine E-Card',
    yourPlaylist: 'deine Playlist:',
  },
  share: {
    buttonDownload: 'Bild herunterladen',
    buttonFact: 'Weitere Fakten',
    shareOn: 'Teilen: ',
    factUrl: 'http://www.udiscover-music.de/?s=guns+n+roses',
    visit: 'Besuche ',
    for: ' für:',
  },
  trackReccomended: {
    yourKey: 'Hör\' dir diesen Track aus',
    albumName: '"Appetite for Destruction" an:',
  },
  web: {
    addAll: 'Hinzufügen',
    added: 'Hinzugefügt',
    expore: 'Entdecke',
    member: {
      tab: 'Projekte',
      title: 'Bandprojekte',
    },
    influence: {
      tab: 'Einflüsse',
      title: 'Einflüsse',
    },
    title: 'Einflüsse und Bandprojekte',
    paragraph: 'Du kannst Guns N‘ Roses Bandprojekte oder weitere musikalische Einflüsse zu deiner Playlist hinzufügen.',
    skip: 'Schritt überspringen',
    next: 'Weiter',
  },
}
