<template>
  <footer class="footer">
    <div class="footer__main">
      <form v-if="!newsletter.subscribed" class="newsletter" @submit.prevent="subscribe">
        <h2 class="subtitle">
          {{$t('footer.title')}}
        </h2>
        <input v-model="newsletter.email" class="input newsletter__email"
               type="text" :placeholder="$t('footer.email')">
        <div class="select__wrapper">
          <select v-model="newsletter.country" class="select newsletter__country" name="" id="">
            <option :value="country.code" v-for="country in countries" :key="country.code">
              {{country.name}}
            </option>
          </select>
        </div>
        <label class="checkbox__wrapper newsletter__terms">
          <input v-model="newsletter.checkbox" class="checkbox" type="checkbox">
          <span class="checkbox__icon"></span>
          <span v-html="$t('footer.terms')"></span>
        </label>
        <button class="button button--ghost newsletter__submit" type="submit">
          {{$t('footer.subscribe')}}
        </button>
      </form>
      <h2 v-else class="subtitle">
        {{$t('footer.subscribeDone')}}
      </h2>
      <ul class="social">
        <li class="social__item" v-for="(mediaItem, index) in media" :key="index">
          <a target="_blank" @click="socialClickout(mediaItem.title)"
             class="social__link" :href="$mediaUrl(mediaItem)">
            <img class="social__icon" :src="mediaItem.icon" :alt="mediaItem.title">
          </a>
        </li>
      </ul>
      <ul class="logos">
        <li class="logos__item">
          <a target="_blank" :href="$mediaUrl(shopping)">
            <img src="../assets/images/logo__footer.png" class="logo__gnr" alt="logo">
          </a>
        </li>
        <li class="logos__item">
          <a target="_blank" :href="spotify.url"><img :src="spotify.icon" :alt="spotify.title"></a>
        </li>
      </ul>
    </div>
    <div class="footer__bottom">
      <ul class="legal">
        <li class="legal__item">
          <a target="_blank" class="legal__link" :href="$t('footer.imprintLink')">
            {{$t('footer.imprint')}}
          </a>
        </li>
        <li class="legal__item">
          <a target="_blank" class="legal__link" :href="$t('footer.privacyLink')">
            {{$t('footer.privacy')}}
          </a>
        </li>
      </ul>
      <span class="copy-right">{{$t('footer.copyright')}}</span>
    </div>
  </footer>
</template>

<script>
  import facebookIcon from '../assets/images/facebook.svg'
  import twitterIcon from '../assets/images/twitter.svg'
  import instagramIcon from '../assets/images/instagram.svg'
  import spotifyIcon from '../assets/images/spotify.svg'
  import youtubeIcon from '../assets/images/youtube.svg'
  import shoppingIcon from '../assets/images/shopping-cart.svg'
  import countriesData from '../data/countries.json'
  import { SHARE_MEDIA } from '../config'
  import { postNewsletter } from '../utils/rest'
  import { newsletterSubscribed, socialClickout } from '../utils/tracker'

  const imageMap = {
    facebook: facebookIcon,
    twitter: twitterIcon,
    instagram: instagramIcon,
    spotify: spotifyIcon,
    youtube: youtubeIcon,
    shopping: shoppingIcon,
  }

  const media = SHARE_MEDIA.map((mediaItem) => {
    mediaItem.icon = imageMap[mediaItem.id]

    return mediaItem
  })

  export default {
    name: 'app-footer',
    data() {
      return {
        media,
        spotify: media.find((m) => m.id === 'spotify'),
        shopping: media.find((m) => m.id === 'shopping'),
        countries: countriesData,
        newsletter: {
          checkbox: false,
          country: 'DE',
          email: '',
          subscribed: false,
        },
      }
    },
    mounted() {
      if (!window.countryCode) return

      this.newsletter.country = window.countryCode
    },
    methods: {
      subscribe() {
        if (!this.newsletter.checkbox || !this.newsletter.email.length) return

        postNewsletter({
          country_code: this.newsletter.country,
          email: this.newsletter.email,
          addressbook_id: '107300',
          form_id: this.$i18n.locale === 'de' ? '-MHjoqgmNHPmDFPf1vnF' : '-MHjpuPKaWVoNUbGQG61',
          // eslint-disable-next-line
          lytics_uid: window.lio ? window.lio._uid : '',
        }).then(() => {
          this.newsletter.subscribed = true
          newsletterSubscribed()
        })
      },
      socialClickout(social) {
        socialClickout(social)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../scss/setup";

  .footer {
    background: $color__black;
    padding: ($spacing * 2) 0;

    &__ {
      &main {
        padding: ($spacing * 4) ($spacing * 5) ($spacing * 2);

        @media screen and (min-width: $media__mobile) {
          padding: 0 0 ($spacing * 2);
        }
      }
      &bottom {
        color: $color__grey;
        font-size: 9px;
        letter-spacing: 2px;
        margin: ($spacing * 4) 0 0;

        @media screen and (min-width: $media__mobile) {
          display: inline-flex;
        }
      }
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
    }
  }

  .subtitle {
    margin: ($spacing * 3) 0 0;
  }

  .newsletter {
    position: relative;
    margin: ($spacing * 4) auto 0;
    width: 100%;

    @media screen and (min-width: $media__mobile) {
      width: 520px;
      padding: 0;
    }

    &__ {
      &terms {
        margin-top: $spacing * 5;
        @media screen and (min-width: $media__mobile) {
          line-height: 28px;
          height: 28px;
        }
      }
      &email, &country {
        @media screen and (min-width: $media__mobile) {
          width: 265px;
          display: block;
        }
      }
      &submit {
        margin-top: $spacing * 5;
        @media screen and (min-width: $media__mobile) {
          position: absolute;
          width: 165px;
          top: $spacing * 5;
          right: 0;
          margin: 0;
        }
      }
    }
  }

  .social {
    margin-top: 230px;

    @media screen and (max-width: $media__mobile) {
      margin-top: 48px;
    }
  }

  .logos {
    margin-top: $spacing * 5;
    &__ {
      &item {
        margin: 0 ($spacing * 2);
      }
    }
  }

  .logo__gnr {
    width: 95px;
    height: 95px;
    margin-bottom: -10px;
  }

  .legal {
    @media screen and (min-width: $media__mobile) {
      order: 2;
      display: inline-block;
    }

    &__ {
      &item {
        text-transform: uppercase;
        width: 50%;

        @media screen and (min-width: $media__mobile) {
          width: inherit;
          margin: 0 ($spacing * 3);
        }
      }
      &link {
        font-weight: bold;
        color: $color__grey;
        font-size: 11px;
        letter-spacing: $spacing__letter--bigger;
      }
    }
  }

  .copy-right {
    margin-top: $spacing * 4;
    display: block;

    @media screen and (min-width: $media__mobile) {
      margin-top: 0;
      order: 1;
      display: inline-block;
    }
  }
</style>
