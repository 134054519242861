<template>
  <main id="app">
    <app-header :hasBorder="!generated"></app-header>
    <transition name="fade">
      <page-result
          v-if="generated" key="page-result"
          :token="token"
          :selected="selected"
          :steps="steps"
          :result="result"
          :fact="fact"
          :factType="factType">
      </page-result>
      <page-generate
          v-else key="page-generate"
          :generated="generated"
          :token="token"
          :selected="selected"
          :steps="steps"
          :result="result"
          :fact="fact"
          :factType="factType">
      </page-generate>
    </transition>
    <app-footer
        :stepsCount="steps.length">
    </app-footer>
  </main>
</template>

<script>
  import AppHeader from './AppHeader.vue'
  import PageGenerate from './PageGenerate.vue'
  import PageResult from './PageResult.vue'
  import AppFooter from './AppFooter.vue'
  import {
    LOCAL_STORAGE_STEPS_KEY,
    LOCAL_STORAGE_SELECTED_KEY,
    LOCAL_STORAGE_TOKEN_KEY,
    MOODS,
  } from '../config'
  import { saveStorage, fetchStorage, removeStorage } from '../utils/local-storage'
  import { getParameterByName } from '../utils/url'
  import { logout } from '../utils/rest'
  import { EventBus } from '../event-bus'
  import factsData from '../data/facts.json'

  export default {
    name: 'app',
    components: {
      AppHeader,
      PageGenerate,
      PageResult,
      AppFooter,
    },
    data() {
      return {
        generated: false,
        token: getParameterByName('access_token'),
        selected: {
          name: '',
          mood: '',
          clusters: [],
          artists: [],
        },
        steps: [1],
        result: {
          playlist: null,
          stats: null,
          profile: null,
        },
      }
    },
    created() {
      EventBus.$on('logout', this.logoutUser)
      EventBus.$on('setGenerated', this.setGenerated)

      if (fetchStorage(LOCAL_STORAGE_TOKEN_KEY)) {
        this.token = fetchStorage(LOCAL_STORAGE_TOKEN_KEY)
      }

      if (this.token) {
        saveStorage(LOCAL_STORAGE_TOKEN_KEY, this.token)
      }

      if (fetchStorage(LOCAL_STORAGE_STEPS_KEY)) {
        this.selected = fetchStorage(LOCAL_STORAGE_STEPS_KEY)
      }
      if (fetchStorage(LOCAL_STORAGE_SELECTED_KEY)) {
        this.steps = fetchStorage(LOCAL_STORAGE_SELECTED_KEY)
      }
    },
    computed: {
      factType() {
        const { clusters } = this.selected

        return clusters.length === 1 && clusters.includes('member') ? 'member' : 'influence'
      },
      factMood() {
        const { mood } = this.selected
        const filtered = MOODS.filter((m) => m !== 'live')

        return mood === 'live' ? filtered[Math.floor(Math.random() * filtered.length)] : mood
      },
      fact() {
        const { factMood: mood } = this

        if (!mood) return {}

        const { influence: inFact, member: memFact } = factsData
        const influenceFact = inFact[mood][Math.floor(Math.random() * inFact[mood].length)]
        const memberFact = memFact[Math.floor(Math.random() * memFact.length)]

        return this.factType === 'influence' ? influenceFact : memberFact
      },
    },
    watch: {
      selected: {
        handler(selected) {
          saveStorage(LOCAL_STORAGE_STEPS_KEY, selected)
        },
        deep: true,
      },
      steps: {
        handler(steps) {
          saveStorage(LOCAL_STORAGE_SELECTED_KEY, steps)
        },
        deep: true,
      },
      result: {
        handler() {
          removeStorage(LOCAL_STORAGE_STEPS_KEY)
          removeStorage(LOCAL_STORAGE_SELECTED_KEY)
        },
        deep: true,
      },
    },
    methods: {
      logoutUser() {
        logout(this.token).then(() => {
          this.token = null
          this.result.profile = null
          removeStorage(LOCAL_STORAGE_TOKEN_KEY)
        })
      },
      setGenerated(bool) {
        this.generated = bool
        this.scrollToTop()
      },
      scrollToTop() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      },
    },
  }

</script>

<style lang="scss">
  @import "../scss/init";

</style>
