import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './components/App.vue'
import us from './locales/en'
import de from './locales/de'
import imageMixin from './mixins/image'
import profileMixin from './mixins/profile'
import mediaMixin from './mixins/media'
import { getLocale } from './utils/rest'

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.mixin(imageMixin)
Vue.mixin(profileMixin)
Vue.mixin(mediaMixin)

const init = async () => {
  const { country_code: countryCode } = await getLocale()
    .then((res) => res)
    .catch((err) => err)
  const locale = ['DE', 'AT', 'CH'].includes(countryCode.toUpperCase()) ? 'de' : 'us'

  window.countryCode = countryCode.toUpperCase()

  return new Vue({
    i18n: new VueI18n({
      locale,
      messages: { us, de },
    }),
    render: (h) => h(App),
  }).$mount('#app')
}

init()
