<template>
  <section>
    <modal :isOpen="isModalOpen" @closeModal="isModalOpen = false"></modal>
    <step-line :number="2"></step-line>
    <div class="row">
      <h2 class="ribbon-title ribbon-title--big">
        <span>
        {{$t('gunsOrRoses.title')}}
        </span>
      </h2>
      <p v-html="$t('gunsOrRoses.paragraph')">
      </p>
    </div>
    <div class="row">
      <figure>
        <h2 class="corvinus">{{$t('gunsOrRoses.guns')}}</h2>
        <img class="logo" :src="logoGuns" alt="Logo Guns">
      </figure>
      <figure class="devider">
        <span class="corvinus small hidden--mobile">{{$t('gunsOrRoses.or')}}</span>
        <span class="corvinus question-mark">?</span>
      </figure>
      <figure>
        <h2 class="corvinus">{{$t('gunsOrRoses.roses')}}</h2>
        <img class="logo" :src="logoRoses" alt="Logo Rose">
      </figure>
    </div>
    <div class="row">
      <button @click="openModal" type="button" class="button">
        {{$t('gunsOrRoses.connectYourSpotify')}}
      </button>
    </div>
    <div class="row">
      <button @click="skipStep" type="button"
              class="button button--ghost button--small hidden--mobile">
        {{$t('gunsOrRoses.skip')}}
      </button>
    </div>
  </section>
</template>

<script>
  import logoGuns from '../assets/images/logo__guns.png'
  import logoRoses from '../assets/images/logo__roses.png'
  import StepLine from './StepLine.vue'
  import Modal from './Modal.vue'
  import { EventBus } from '../event-bus'
  import { openSpotifyModal } from '../utils/tracker'

  export default {
    name: 'guns-or-roses',
    components: { StepLine, Modal },
    data() {
      return {
        logoGuns,
        logoRoses,
        isModalOpen: false,
      }
    },
    methods: {
      skipStep() {
        EventBus.$emit('nextStep', 3, true)
      },
      openModal() {
        this.isModalOpen = true
        openSpotifyModal()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../scss/setup";

  section {
    // padding-bottom: $spacing * 10;
  }

  figure {
    display: inline-block;
    position: relative;
    margin: 0;

    @media screen and (max-width: $media__mobile) {
      display: block;

      & + figure {
        margin-top: -$spacing * 5;
      }
    }

    h2 {
      position: absolute;
      display: block;
      margin: 0 auto;
      width: 100%;
      top: 0;
    }

    span {
      display: block;
      &.small {
        font-size: 32px;
      }

      &.question-mark {
        font-size: 82px;
        margin-top: $spacing * 2;
      }
    }

    &.devider {
      vertical-align: top;
      padding: 0 ($spacing * 5);

      @media screen and (max-width: $media__mobile) {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0;
        z-index: 1;
      }
    }
  }

  .logo {
    width: 213px;
  }
</style>
