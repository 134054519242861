export function fetchStorage(key) {
  return JSON.parse(localStorage.getItem(key) || false)
}

export function saveStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data))
}

export function removeStorage(key) {
  localStorage.removeItem(key)
}
