<template>
  <div class="page-result">
    <div class="row">
      <stats :stats="result.stats" v-if="result && result.stats" />
      <div>
        <h2 class="ribbon-title title">
          <span>{{$t('result.yourPlaylist')}}</span>
        </h2>
      </div>
      <div id="col--1" class="col col--4 col-tp--12 spacing">
        <overview
          :mood="selected.mood"
          :playlistName="selected.name"
          :profile="result.profile"
          :clusters="selected.clusters"
          :artists="selected.artists"
        ></overview>
      </div>
      <div id="col--2" class="col col--4 col-tp--12 spacing">
        <playlist :playlist="result.playlist"></playlist>
      </div>
      <div id="col--3" class="col col--4 col-tp--12 spacing">
        <div class="actions">
          <a
            :href="result.playlist.uri"
            @click="openInSpotify"
            class="button"
          >{{$t('result.openInSpotify')}}</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div>
        <h2 class="ribbon-title title">
          <span>{{$t('result.shop')}}</span>
        </h2>
      </div>
      <h2 class="title--white" v-html="$t('result.shopAlbum')"></h2>
      <div class="col col--4 col-tp--12 spacing">
        <!-- <track-reccomend v-if="result.stats && result.stats.recommended_track"
                         :track="result.stats.recommended_track">
        </track-reccomend>-->
      </div>
      <div class="col col--4 col-tp--12 spacing">
        <img class="album-image" :src="albumImage" alt="Appetite for Destruction" />
      </div>
      <div class="col col--4 col-tp--12 spacing">
        <div class="actions">
          <a
            :href="$mediaUrl(shopMedia)"
            target="_blank"
            class="button"
            data-umg-target="linkfire"
            data-umg-type="Buy"
          >{{$t('result.buy')}}</a>
        </div>
      </div>
    </div>
    <div class="row restart">
      <div class="col col--4 col-tp--12 spacing"></div>
      <div class="col col--4 col-tp--12 spacing">
        <button
          @click="restart"
          type="button"
          class="button button-circle active"
          :class="{ 'button--small': $i18n.locale === 'de' }"
        >
          {{$t('result.restart')}}
          <i class="circle3"></i>
        </button>
      </div>
      <div class="col col--4 col-tp--12 spacing"></div>
    </div>
  </div>
</template>

<script>
  import Overview from './Overview.vue';
  import Stats from './Stats.vue';
  import Playlist from './Playlist.vue';
  import albumImage from '../assets/images/album.png';
  import { SHARE_MEDIA } from '../config';
  import { makeAnotherOneClicked, openInSpotify } from '../utils/tracker';

  export default {
    name: 'page-result',
    components: {
      Overview,
      Playlist,
      Stats,
    },
    props: {
      selected: {
        type: Object,
      },
      steps: {
        type: Array,
      },
      result: {
        type: Object,
      },
      token: {
        type: String,
      },
    },
    data() {
      return {
        albumImage,
        shopMedia: SHARE_MEDIA.find((m) => m.id === 'shopping'),
      };
    },
    methods: {
      restart() {
        makeAnotherOneClicked();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        window.location.reload();
      },
      openInSpotify() {
        openInSpotify();
      },
    },
  };
</script>

<style lang="scss" scoped>
@import "../scss/setup";

$padding__row: $spacing * 6;

.fact-credit {
  margin-top: $spacing * 4;

  @media screen and (max-width: $media__table-portrait) {
    margin-top: $spacing * 3;
  }
}

.page-result {
  background: url("../assets/images/background.png");
  background-position: center;
  background-repeat: repeat-y;
  background-size: contain;

  @media screen and (max-width: $media__table-portrait) {
    padding: 0 20px;
  }
}

.row {
  padding-top: $padding__row;
  padding-bottom: $padding__row;

  @media screen and (max-width: $media__table-portrait) {
    padding-top: $spacing * 4;
    display: grid;

    .col {
      width: 100%;

      &#col {
        &-- {
          &1 {
            order: 3;
          }
          &2 {
            order: 1;
          }
          &3 {
            order: 2;
          }
        }
      }
    }
  }
}

.title.title {
  margin-bottom: $spacing * 5;

  @media screen and (min-width: $media__table-portrait) {
    margin-bottom: $padding__row;
  }
}

h2.title--white {
  line-height: 32px;
  margin-bottom: $spacing * 3;
  margin-top: 0;
}

.album-image {
  width: 100%;
}

.restart {
  padding-bottom: $spacing * 15;
  margin-bottom: 0;

  .button--small {
    font-size: 20px;
  }
}

.actions {
  margin: ($spacing * 5) 0;

  @media screen and (min-width: $media__table-portrait) {
    width: $actions__width;
    margin: 0 auto;

    ::v-deep .button {
      width: 100%;
      display: block;
    }
  }
}
</style>
