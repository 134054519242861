export const TOTAL_STEPS = 5
export const STEPS_COUNT_SHOW_PROGRESS = 1
export const SCROLL_OFFSET_TOP = 64
export const MOODS = ['rage', 'psyched', 'ballad', 'rock_n_roll', 'live']
export const SHARE_MEDIA = [
  {
    id: 'facebook',
    title: 'Facebook',
    url: 'https://www.facebook.com/gunsnroses',
  },
  {
    id: 'twitter',
    title: 'Twitter',
    url: 'https://twitter.com/gunsnroses',
  },
  {
    id: 'instagram',
    title: 'Instagram',
    url: 'https://www.instagram.com/gunsnroses/',
  },
  {
    id: 'spotify',
    title: 'Spotify',
    url: 'https://open.spotify.com/artist/3qm84nBOXUEQ2vnTfUTTFC',
  },
  {
    id: 'shopping',
    title: 'Shopping',
    url: {
      translation: 'shopUrl',
    },
  },
]
export const SLICK_OPTIONS = {
  dots: false,
  centerMode: true,
  slidesToShow: 1,
  variableWidth: true,
  arrows: true,
  prevArrow: '<span class="mood__nav mood__nav--prev"></span>',
  nextArrow: '<span class="mood__nav mood__nav--next"></span>',
}
export const ARTIST_LIMIT = 5
export const WEB_INFLUENCE = 7
export const WEB_YELLOW_DOTS = [1, 3, 5]
export const LOGIN_URL = '/auth/spotify/login'
export const LOCAL_STORAGE_STEPS_KEY = 'steps'
export const LOCAL_STORAGE_SELECTED_KEY = 'selected'
export const LOCAL_STORAGE_TOKEN_KEY = 'token'
export const LOCALE_FALLBACK = 'us'
