<template>
  <header class="header" :class="{ 'header--border': hasBorder }">
    <div class="header-content">
      <logo class="logo"></logo>
      <h1 class="corvinus">{{$t('header.title')}}</h1>
      <h2 class="second-title" v-html="$t('header.secondTitle')"></h2>
      <h2 class="subtitle">{{$t('header.subtitle')}}</h2>
    </div>
    <ul class="language">
      <li @click="setLanguage('de')"
          :class="{ active: isActive('de') }"
          class="language__item">de</li>
      <li @click="setLanguage('us')"
          :class="{ active: isActive('us') }"
          class="language__item">en</li>
    </ul>
  </header>
</template>

<script>
  import Logo from './Logo.vue'

  export default {
    name: 'app-header',
    components: { Logo },
    props: {
      hasBorder: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      setLanguage(lang) {
        if (this.isActive(lang)) {
          return
        }

        this.$i18n.locale = lang
      },
      isActive(lang) {
        return this.$i18n.locale === lang
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../scss/setup";

  .header {
    padding-bottom: $spacing * 8;
    background: url('../assets/images/background.png');
    background-size: cover;
    background-position: center;

    @media screen and (max-width: $media__table-portrait) {
      background-size: contain;
      padding-bottom: $spacing * 2;
    }

    &--border {
      border-bottom: 1px solid #818182;
      margin-bottom: 2px;
      padding-bottom: $spacing * 10;
    }
  }

  .header-content {
    max-width: 495px;
    display: block;
    margin: 0 auto;

    @media screen and (max-width: $media__mobile) {
      max-width: 100%;
      padding: 0 $spacing;
    }
  }

  h1.corvinus {
    // margin: ($spacing * 4) 0 0;
    line-height: 60px;

    @media screen and (max-width: $media__mobile) {
      font-size: 44px;
      line-height: 44px;
    }
  }

  h2.subtitle {
    margin-top: $spacing * 2;
  }

  h2.second-title {
    margin: $spacing 0;
    color: #B1B1B1;
    letter-spacing: 6px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 900;
    border-bottom: 1px solid $color__white;
    border-top: 1px solid $color__white;
    text-transform: uppercase;

    @media screen and (max-width: $media__mobile) {
      font-size: 15px;
    }

    ::v-deep {
      span {
        color: $color__white;
      }
    }
  }

  .language {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: $spacing * 5;
    right: $spacing * 5;

    @media screen and (max-width: $media__mobile) {
      top: $spacing * 2;
      right: $spacing;
    }

    &__ {
      &item {
        display: inline-block;
        margin: 0 $spacing;
        cursor: pointer;
        color: $color__white;
        position: relative;

        &:first-child {
          &:after {
            content: '|';
            position: absolute;
            margin-left: 6px;
          }
        }

        &.active {
          &:before {
            content: '';
            position: absolute;
            width: 5px;
            height: 5px;
            background: $color__white;
            top: 20px;
            left: 8px;
            border-radius: 50%;
          }
        }
      }
    }
  }

</style>
