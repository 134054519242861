<template>
  <img class="logo" :src="logo" alt="Guns n Roses logo">
</template>

<script>
  import logo from '../assets/images/logo.png'

  export default {
    name: 'logo',
    data() {
      return {
        logo,
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../scss/setup";

  .logo {
    width: 124px;

    @media screen and (min-width: $media__mobile) {
  width: 229px;
    }
  }
</style>
