<template>
  <div class="line">
    <div class="line__title">{{$t('line.step')}}</div>
    <div class="line__number">
      <span>{{number}}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'step-line',
    props: {
      number: {
        type: Number,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
@import "../scss/setup";

$number__size: 42px;

.line {
  position: relative;
  top: -106px;

  &__ {
    &title {
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: $spacing;
      text-align: center;
      letter-spacing: 0.17em;
      text-transform: uppercase;
      color: #EEEEEE;
    }
    &number {
      letter-spacing: 0.05em;
      color: $color__white;
      position: relative;
      font-family: "Corvinus Skyline IC", sans-serif;

      span {
        position: absolute;
        z-index: 1;
        font-size: 26px;
        left: 50%;
        transform: translateX(-50%);
        top: 16px;
      }

      &:after {
        content: "";
        position: absolute;
        background: url("../assets/images/hole.png");
        background-size: cover;
        height: 64px;
        width: 80px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
