<template>
  <figure @click="setMood(mood)" class="mood__head" :class="{ active: chosenMood === mood }">
    <img :src="image" :alt="title" />
    <h5>{{$t('mood.select')}}</h5>
  </figure>
</template>

<script>
  import { EventBus } from '../event-bus';

  export default {
    name: 'mood-head',
    props: {
      mood: {
        type: String,
      },
      image: {
        type: String,
      },
      chosenMood: {
        type: String,
      },
    },
    computed: {
      title() {
        return this.$t(`mood.${this.mood}`);
      },
    },
    methods: {
      setMood(moodId) {
        EventBus.$emit('nextStep', 2);
        EventBus.$emit('setMood', moodId);
      },
    },
  };
</script>

<style lang="scss" scoped>
@import "../scss/setup";
@import "../scss/ribbon";

$ribbon__transition-time: 0.4s;

figure {
  img {
    width: 100%;
  }

  h5 {
    display: none;
    // text-transform: uppercase;
    // letter-spacing: $spacing__letter--medium;;
    // color: $color--white;
    // // @extend %ribbon--full;
    // transition: $ribbon__transition-time;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #ffffff;

    @media screen and (max-width: $media__table-portrait) {
      display: block;
      border: 1px solid $color__grey;
      height: 65px;
      line-height: 65px;
      border-radius: 65px;
      opacity: 0;
      transition: $ribbon__transition-time;

      &:after {
        content: none;
      }
    }

    @media screen and (max-width: $media__mobile--small) {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
