export default {
  artists: {
    title: 'Add more artists',
    paragraph: 'Manually add more artists to your playlist.',
    placeholder: 'Type here...',
    next: 'next',
  },
  ecard: {
    areYou: 'Are You',
    didYouKnow: 'Did you know?',
    fact: 'Fact',
    generateOwn: 'Generate your own Guns N Roses Playlist on:',
    guns: 'Guns',
    hashtag: '#appetitefordestruction',
    influence: 'Influence',
    is: 'is',
    member: 'Sideproject',
    orAre: 'or are',
    percentGuns: '{0}% Guns',
    percentRoses: '{0}% Roses',
    url: 'www.gunsnrosesplaylists.com',
    youRoses: 'you Roses?',
  },
  footer: {
    copyright: '© 2020 UNIVERSAL MUSIC GMBH. ALL RIGHTS RESERVED.',
    email: 'Your email',
    imprint: 'Imprint',
    imprintLink: 'https://www.universal-music.de/rechtliche-hinweise/imprint',
    privacy: 'Privacy Policy',
    privacyLink: 'https://www.universal-music.de/rechtliche-hinweise/security-and-privacy',
    subscribe: 'Subscribe',
    subscribeDone: 'Thank you for subscribing',
    terms: 'You confirm that you want to receive our newsletters about Guns \'N Roses. Receive the latest information about releases, giveaways and contests by e-mail. You may withdraw your consent at any time. More information at <a href="https://www.universal-music.de/rechtliche-hinweise/security-and-privacy" target="_blank">Security & Privacy.</a>',
    title: 'Subscribe to the Guns n\' Roses newsletter',
  },
  generate: {
    paragraph: 'You are all set! Hit generate and let the magic begin.  Are you Guns or are you Roses? ',
    title: 'All set!',
    placeholder: 'Name your playlist',
    button: 'Generate Playlist',
  },
  gunsOrRoses: {
    connectYourSpotify: 'Connect your Spotify',
    guns: 'Guns',
    or: 'or',
    paragraph: 'Connect your Spotify account to match your Top Artists with Guns N\' Roses. Let your listening behavior decide: Are you Guns or are you Roses?',
    roses: 'Roses',
    skip: 'skip this step',
    title: 'Are you Guns or Roses?',
  },
  header: {
    title: 'Welcome to the playlists',
    subtitle: 'Generate your own personal Guns N’ Roses playlists',
    secondTitle: 'Are you <span>guns</span> or <span>roses</span>?',
  },
  modal: {
    title: 'Why connect?',
    paragraph: 'By connecting with your Spotify account we can find out if you are more into upbeat songs or if you like to take a more relaxed approach when it comes to music. And we will tell you: Are you Guns or are you Roses?',
    terms: 'More information on Social Connect and the use of your data at <a href="https://www.universal-music.de/rechtliche-hinweise/security-and-privacy" rel="noopener noreferrer" target="_blank">Security and Privacy</a>.',
    connect: 'Connect now',
    abort: 'Abort',
  },
  mood: {
    title: 'Select your mood',
    paragraph: 'Discover the sound of Guns \'N Roses. Pick a mood by clicking on your prefered skull.',
    rage: 'Rage',
    psyched: 'Ecstatic',
    ballad: 'Ballad',
    rock_n_roll: 'Rock n\' Roll',
    live: 'Live',
    select: 'Select',
  },
  overview: {
    addedArtists: 'Added artists: ',
    basedOn: 'Based on: ',
    connected: 'Connected as: ',
    disconnect: 'Disconnect',
    namedIt: 'You named it: ',
    none: 'None',
    notConnected: 'Not connected',
    selected: 'Selected: ',
    title: 'Summary',
    you: 'You ',
  },
  media: {
    shopUrl: 'https://crm-international.lnk.to/GNROfficialStorePl',
  },
  line: {
    step: 'Step',
  },
  progressBar: {
    button: 'Generate Playlist',
    buttonMobile: 'Generate',
    complete: 'complete',
    skip: 'Skip',
    stepOf: 'Step {0}/{1}',
  },
  result: {
    buy: 'Go to Shop',
    creditFacts: '© Credit Facts: Christof Leim',
    openInSpotify: 'Open in Spotify',
    restart: 'Make another one',
    shop: 'Shop',
    shopAlbum: 'Get the Greatest Hits - on Vinyl for the first time',
    yourEcard: 'Your E-card',
    yourPlaylist: 'Your Playlist:',
  },
  share: {
    buttonDownload: 'Download image',
    buttonFact: 'More facts',
    shareOn: 'Share on: ',
    factUrl: 'https://www.udiscovermusic.com/artists/guns-n-roses/',
    visit: 'Visit ',
    for: ' for:',
  },
  trackReccomended: {
    yourKey: 'Your key track on',
    albumName: 'appetite for destruction:',
  },
  web: {
    addAll: 'Add to playlist',
    added: 'Added',
    expore: 'Explore',
    member: {
      tab: 'Add guns',
      title: 'SideProjects',
    },
    influence: {
      tab: 'Under influence',
      title: 'Influences',
    },
    title: 'Add members or influences?',
    paragraph: 'Add side projects and influences to discover the Bands that inspired Guns N\' Roses.',
    skip: 'skip this step',
    next: 'next',
  },
}
