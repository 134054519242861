<template>
  <div class="stats">
    <div class="stats__number stats__number--primary corvinus">
      {{gunsPercent}}%
      <br />Guns
    </div>
    <div class="stats__wrapper">
      <div class="stats__pie">
      <div class="stats__circle">
        <svg id="pie" width="200" height="200" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle
            r="90"
            cx="100"
            cy="100"
            fill="transparent"
            stroke-dasharray="565.48"
            stroke-dashoffset="0"
          />
          <circle
            id="bar"
            r="90"
            cx="100"
            cy="100"
            fill="transparent"
            stroke-dasharray="565.48"
            :style="{ strokeDashoffset: strokeDashoffset }"
            stroke-dashoffset="0"
          />
        </svg>
      </div>
      <!--Logo image-->
      <img class="stats__image" v-if="gunsPercent > rosesPercent" :src="logoGuns" alt="logo guns" />
      <img class="stats__image" v-else :src="logoRoses" alt="logo guns" />

      </div>
    </div>
    <div class="stats__number corvinus">
      {{rosesPercent}}%
      <br />Roses
    </div>
    <!-- <span
      id="text6"
      class="ecard__text ecard__text--red"
    >{{$t('ecard.percentRoses', [rosesPercent])}}</span>-->
  </div>
</template>

<script>
  import logoGuns from '../assets/images/logo__guns.png';
  import logoRoses from '../assets/images/logo__roses.png';

  export default {
    name: 'ecard',
    props: {
      stats: {
        type: Object,
      },
    },
    data() {
      return {
        logoGuns,
        logoRoses,
      };
    },
    computed: {
      strokeDashoffset() {
        return ((100 - this.rosesPercent) / 100) * (Math.PI * (90 * 2));
      },
      gunsPercent() {
        return (this.stats.guns_pct * 100).toFixed();
      },
      rosesPercent() {
        return (this.stats.roses_pct * 100).toFixed();
      },
    },
  };
</script>

<style lang="scss" scoped>
@import "../scss/setup";

.stats {
  width: 680px;
  margin: 0 auto 88px;
  display: flex;
}

.stats__number {
  width: 215px;
  font-size: 72px;
  line-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--primary {
    color: $color__primary;
  }
}

.stats__wrapper {
  position: relative;
  height: 250px;
  width: 250px;
}

.stats__image {
  width: 230px;
  position: relative;
}

.stats__circle {
  position: absolute;
  top: 0;
  transform: scale(1.25);
  left: 0;
  transform-origin: 0px 64px;
}

@media screen and (max-width: $media__mobile) {
  .stats {
    width: 100%;
    justify-content: stretch;
    margin: 0 auto 24px;
  }
  .stats__wrapper {
    flex-grow: 1;
    width: 150px;

    .stats__pie {
      transform: scale(.6);
      transform-origin: 0;

      .stats__image {
        margin-left: 12px;
      }
    }
  }
  .stats__number {
    flex-grow: 1;
    width: inherit;
    font-size: 42px;
    line-height: 44px;
    margin-top: -54px;

    &--primary {
      margin-top: 0;
    }
  }
}

</style>
