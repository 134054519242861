<template>
  <section>
    <step-line :number="4"></step-line>
    <div class="row">
      <h2 class="ribbon-title">
        <span>{{$t('artists.title')}}</span>
      </h2>
      <p v-html="$t('artists.paragraph')"></p>
    </div>
    <div class="artists">
      <form @submit.prevent="searchArtist">
        <input class="artists__input input" type="text" v-model="query"
               @keyup="searchArtist"
               :placeholder="$t('artists.placeholder')">
        <i @click="resetQuery" class="artists__search-icon icon"
           :class="queryHasLength ? 'icon--cross' : 'icon--plus'"></i>
      </form>
      <artists-list v-if="queryHasLength"
                    :artists="artistsSearched" :artistsSelected="artistsSelected"></artists-list>
      <artists-list v-else
                    :artists="artistsSelected" :artistsSelected="artistsSelected"></artists-list>
      <button @click="nextStep" type="button" class="button artists__next">
        {{$t('artists.next')}}
      </button>
    </div>
    <div class="overlay" @click="resetQuery" :class="{ active: queryHasLength }"></div>
  </section>
</template>

<script>
  import debounce from 'lodash/debounce'
  import StepLine from './StepLine.vue'
  import ArtistsList from './ArtistsList.vue'
  import { searchArtist } from '../utils/rest'
  import { EventBus } from '../event-bus'

  export default {
    name: 'artists',
    components: { StepLine, ArtistsList },
    props: {
      artistsSelected: {
        type: Array,
      },
    },
    data() {
      return {
        query: '',
        artistsSearched: [],
        searching: false,
      }
    },
    created() {
      EventBus.$on('resetArtistSearchQuery', this.resetQuery)
    },
    computed: {
      queryHasLength() {
        return this.query.length > 0
      },
    },
    methods: {
      /* eslint func-names: 'off' */ // Needed for debounce
      searchArtist: debounce(function () {
        searchArtist(this.query)
          .then((artists) => {
            this.artistsSearched = artists
            this.searching = false
          })
      }, 150),
      resetQuery() {
        this.query = ''
        this.artistsSearched = []
      },
      nextStep() {
        EventBus.$emit('nextStep', 5)
        this.resetQuery()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../scss/setup";

  $search__width: 350px;

  .artists {
    max-width: $search__width;
    margin: 0 auto;
    position: relative;
    z-index: 1000;

    &__ {
      &search-icon {
        position: absolute;
        top: $spacing;
        right: $spacing;
      }
      &next {
        width: 164px;
        margin-bottom: $spacing * 3;
      }
      &input {
        &::placeholder {
          text-align: left;
        }
      }
    }
  }
</style>
