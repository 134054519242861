<template>
  <div class="modal">
    <div class="modal__content" :class="{ active: isOpen }">
      <img class="modal__logo" :src="spotifyIcon" alt="spotify logo">
      <h2 class="title--white">{{$t('modal.title')}}</h2>
      <p class="paragraph--small" v-html="$t('modal.paragraph')"></p>
      <p class="modal__terms" v-html="$t('modal.terms')"></p>
      <button @click="login" class="modal__button button" type="button">
        {{$t('modal.connect')}}
      </button>
      <button @click="abort"
              class="modal__button button button--small button--ghost" type="button">
        {{$t('modal.abort')}}
      </button>
    </div>
    <div class="overlay" @click="closeModal" :class="{ active: isOpen }"></div>
  </div>
</template>

<script>
  import spotifyIcon from '../assets/images/spotify.svg'
  import { LOGIN_URL } from '../config'
  import { EventBus } from '../event-bus'
  import { spotifyLogin, closeSpotifyModal } from '../utils/tracker'

  export default {
    name: 'modal',
    props: {
      isOpen: {
        type: Boolean,
      },
    },
    data() {
      return {
        spotifyIcon,
      }
    },
    methods: {
      abort() {
        closeSpotifyModal()
        this.closeModal()
      },
      closeModal() {
        this.$emit('closeModal')
      },
      login() {
        EventBus.$emit('nextStep', 3)
        spotifyLogin()
        window.location = `${LOGIN_URL}?redirect_uri=${encodeURIComponent(window.location.origin + window.location.pathname)}`
      },
    },
  }

</script>
<style lang="scss" scoped>
  @import "../scss/setup";

  $modal__wdith: 700px;
  $modal__height: 620px;

  .modal {
    &__ {
      &paragraph {
        // line-height: 24px;
      }
      &content {
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 90%;
        opacity: 0;
        visibility: hidden;
        height: $modal__height;
        background: $color__grey--darker;
        position: fixed;
        border: 2px solid $color__white;
        border-radius: 20px;
        transition: $overlay__animate;
        z-index: 9999;
        @media screen and (max-width: $media__mobile) {
          overflow: scroll;
          padding: 2.5%;
          height: 90vh;
        }
        @media screen and (min-width: $media__table-portrait) {
          width: $modal__wdith;
        }
        &.active {
          opacity: 1;
          visibility: visible;
        }
      }
      &logo {
        margin-top: $spacing * 3;
        height: $spacing * 10;
      }
      &button {
        display: block;
        margin: ($spacing * 3) auto 0;
      }
      &terms {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
</style>
