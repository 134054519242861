<template>
  <div class="overview">
    <h2 class="overview__title corvinus">{{$t('overview.title')}}</h2>
    <ul class="overview__list">
      <li class="overview__item">
        <div class="overview__number">
          <span>1</span>
        </div>
        <div class="overview__content">
          {{$t('overview.basedOn')}}
          <span class="highlight">{{$t('mood.' + mood)}}</span>
        </div>
      </li>
      <li class="overview__item">
        <div class="overview__number">
          <span>2</span>
        </div>
        <div class="overview__content">
          <img class="overview__icon" :src="spotifyIcon" alt />
          <template v-if="profile">
            {{$t('overview.connected')}}
            <span class="highlight">{{$profileName(profile)}}</span>
            <button
              @click="logout"
              class="overview__button button button--ghost button--tiny"
            >{{$t('overview.disconnect')}}</button>
          </template>
          <template v-else>
            <span class="highlight">{{$t('overview.notConnected')}}</span>
          </template>
        </div>
      </li>
      <li class="overview__item">
        <div class="overview__number">
          <span>3</span>
        </div>
        <div class="overview__content">
          {{$t('overview.selected')}}
          <span class="highlight">{{clustersName}}</span>
        </div>
      </li>
      <li class="overview__item">
        <div class="overview__number">
          <span>4</span>
        </div>
        <div class="overview__content">
          {{$t('overview.addedArtists')}}
          <artists-list
            v-if="artists.length"
            class="overview__artists"
            :artists="artists"
            :alllowDelete="false"
          ></artists-list>
          <span class="highlight" v-else>{{$t('overview.none')}}</span>
        </div>
      </li>
      <li class="overview__item">
        <div class="overview__number">
          <span>5</span>
        </div>
        <div class="overview__content">
          {{$t('overview.namedIt')}}
          <span class="highlight">{{playlistName}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import ArtistsList from './ArtistsList.vue';
  import spotifyIcon from '../assets/images/spotify.svg';
  import { EventBus } from '../event-bus';
  import { disconnectSpotify } from '../utils/tracker';

  export default {
    name: 'overview',
    components: { ArtistsList },
    props: {
      playlistName: {
        type: String,
      },
      mood: {
        type: String,
      },
      profile: {
        type: Object,
      },
      clusters: {
        type: Array,
      },
      artists: {
        type: Array,
      },
    },
    data() {
      return {
        spotifyIcon,
      };
    },
    computed: {
      clustersName() {
        if (this.clusters.length <= 0) {
          return this.$t('overview.none');
        }

        return this.clusters
          .map((cluster) => this.$t(`web.${cluster}.tab`))
          .join(' & ');
      },
    },
    methods: {
      logout() {
        disconnectSpotify();
        EventBus.$emit('logout');
      },
    },
  };
</script>

<style lang="scss" scoped>
@import "../scss/setup";

.overview {
  margin: 0 ($spacing * 2) 0 ($spacing * 5);
  color: #999999;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;

  @media screen and (max-width: $media__table-portrait) {
    margin: 0;
  }

  &__ {
    &title {
      text-align: left;
      margin-left: $spacing;
      margin-left: 84px;
      margin-bottom: 16px;
      font-size: 42px;
      line-height: 44px;
    }
    &list {
      position: relative;
      text-align: left;
      list-style: none;
      padding: 0;
      margin: 0;
      font-weight: 800;
      letter-spacing: $spacing__letter--medium;
    }
    &item {
      min-height: $spacing * 5;
      margin-bottom: $spacing;
      display: flex;
    }
    &number {
      position: relative;
      // width: ($spacing * 4) + 2px; // border
      // height: ($spacing * 4) + 2px; // border
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 55px;
      width: 60px;
      // line-height: ($spacing * 4) + 4px;
      // margin-right: $spacing * 2;

      span {
        letter-spacing: 0.05em;
        color: $color__white;
        position: relative;
        font-family: "Corvinus Skyline IC", sans-serif;
        z-index: 1;
        display: block;
        margin-left: 7px;
        margin-bottom: 4px;
      }

      &:after {
        content: "";
        position: absolute;
        background: url("../assets/images/hole.png");
        background-size: cover;
        height: 55px;
        width: 60px;
        left: 50%;
        transform: translateX(-50%);
      }

      // &:after {
      //   content: '';
      //   width: $spacing * 4;
      //   height: $spacing * 4;
      //   left: 0;
      //   top: 0;
      //   position: absolute;
      //   background: $color__grey--darker;
      //   border: 1px solid $color__primary;
      //   border-radius: 50%;
      //   z-index: 1;
      // }
    }
    &icon {
      width: 22px;
      height: 22px;
      vertical-align: sub;
      margin-right: $spacing;
    }
    &content {
      margin-top: $spacing * 2;
      margin-left: $spacing * 2;
      padding: 0 $spacing;
      @media screen and (max-width: $media__table-portrait) {
        padding: 0;
      }
    }
    &button {
      margin-top: $spacing * 2;
      display: block;
    }
    &artists {
      max-width: $form__width;
      min-height: 0;
      margin-top: $spacing;
    }
  }
}
</style>
