function pushEvent(attributes = {}) {
  if (!window.dataLayer) {
    return true
  }

  window.dataLayer.push({
    event: 'GAEvent',
    attributes: {
      eventUmgArtist: 'Guns n\' Roses',
      eventCategory: 'CRM',
      ...attributes,
    },
  })
  return true
}

export function moodSelected(moodName) {
  return pushEvent({
    eventAction: 'PLG_SelectMood',
    eventLabel: moodName,
  })
}

/*
 name = 'influence' or 'member'
 */
export function clusterSelected(name) {
  return pushEvent({
    eventAction: 'PLG_SelectCluster',
    eventLabel: name,
  })
}

export function clusterRemoved(name) {
  return pushEvent({
    eventAction: 'PLG_RemoveCluster',
    eventLabel: name,
  })
}

export function seedArtistAdded(artistName) {
  return pushEvent({
    eventAction: 'PLG_AddArtist',
    eventLabel: artistName,
  })
}

export function seedArtistRemoved(artistName) {
  return pushEvent({
    eventAction: 'PLG_RemoveArtist',
    eventLabel: artistName,
  })
}

/*
 On succesful spotify login
 */
export function spotifyLogin() {
  return pushEvent({
    eventAction: 'PLG_LoginSpotify_UserLogin',
  })
}

export function skipClicked(currentStep) {
  return pushEvent({
    eventAction: 'PLG_SkipStep',
    eventLabel: currentStep,
  })
}

export function openSpotifyModal() {
  return pushEvent({
    eventAction: 'PLG_LoginSpotify_OpenLayer',
    eventLabel: 'Click on "Connect your Spotify"',
  })
}

export function closeSpotifyModal() {
  return pushEvent({
    eventAction: 'PLG_LoginSpotify_OpenLayer',
    eventLabel: 'Click on "Connect your Spotify"',
  })
}

/*
 name: The playlist name (empty string if not supplied)
 seedArtists: comma separated list of artist ids
 clusterNames: comma separated list of clusters
 */
export function generatePlaylistClicked(name = '', seedArtists = '', clusterNames = '') {
  pushEvent({
    eventAction: 'PLG_BuildPlaylist_UserSubmit',
    eventLabel: name,
  })
  pushEvent({
    eventAction: 'PLG_BuildPlaylist_SeedArtists',
    eventLabel: seedArtists,
  })
  return pushEvent({
    eventAction: 'PLG_BuildPlaylist_Clusters',
    eventLabel: clusterNames,
  })
}

export function newsletterSubscribed() {
  return pushEvent({
    eventAction: 'PLG_NewsletterSubscribed',
    eventLabel: 'Guns n\' Roses',
  })
}

/*
 When social links clicked (facebook, twitter, etc)
 */
export function socialClickout(serviceName) {
  return pushEvent({
    eventAction: `${serviceName} Clickout`,
    eventLabel: 'Guns n Roses',
  })
}

export function resultsShared(serviceName, shareUrl) {
  return pushEvent({
    eventAction: `${serviceName}Share`,
    eventCategory: 'Social',
    eventLabel: shareUrl,
  })
}

/*
 Result page
 */

export function disconnectSpotify() {
  return pushEvent({
    eventAction: 'PLG_Spotify_UserDisconnect',
    eventLabel: 'Click on "Disconnect"',
  })
}

export function openInSpotify() {
  return pushEvent({
    eventAction: 'PLG_OpenPlaylist',
    eventLabel: 'Open in Spotify',
  })
}

export function downloadImage() {
  return pushEvent({
    eventAction: 'PLG_DownloadImage',
    eventLabel: 'Download Image',
  })
}

export function moreFacts() {
  return pushEvent({
    eventAction: 'PLG_MoreFacts',
    eventLabel: 'More Facts',
  })
}

export function makeAnotherOneClicked() {
  return pushEvent({
    eventAction: 'PLG_CreateMorePL',
    eventLabel: '',
  })
}
