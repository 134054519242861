<template>
  <ul class="list">
    <li class="list__item" v-for="(artist, index) in artists" :key="index"
        @click="toggleArtist(artist)">
      <img class="list__image image-circle image-circle--small" v-if="$imageArtist(artist)"
           :src="$imageArtist(artist)" :alt="artist.name"
           width="50" height="50">
      <span :class="{ 'padding--left': !$imageArtist(artist)  }">{{artist.name}}</span>
      <i v-if="alllowDelete" class="list__icon icon"
         :class="artistIsSelected(artist) ? 'icon--cross' : 'icon--plus'"></i>
    </li>
  </ul>
</template>

<script>
  import { EventBus } from '../event-bus'

  export default {
    name: 'arists-list',
    props: {
      artists: {
        required: true,
        type: Array,
      },
      artistsSelected: {
        type: Array,
        default() {
          return []
        },
      },
      alllowDelete: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      toggleArtist(artist) {
        EventBus.$emit('toggleArtist', artist)
        EventBus.$emit('resetArtistSearchQuery')
      },
      artistIsSelected(artist) {
        return this.artistsSelected.includes(artist)
      },
      artistSelectedIndex(artist) {
        return this.artistsSelected.findIndex((a) => a.spotify_id === artist.spotify_id)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../scss/setup";

  .padding--left {
    padding-left: 52px;
  }

  .list {
    margin: 0;
    padding: 0 0 ($spacing * 2);
    text-align: left;
    list-style: none;
    min-height: 340px;

    &__ {
      &item {
        height: 42px;
        line-height: 42px;
        padding: 9px 0;
        border-bottom: 1px solid $color__grey;
        display: flex;
        position: relative;

        &:hover {
          background: $color__grey--medium;
        }
      }
      &image {
        margin: 0 6px;
      }
      &icon {
        right: $spacing;
        top: 14px;
        position: absolute;
      }
    }
  }
</style>
