<template>
  <div class="progress__wrapper" :class="{ active: isActive, 'text-small': isTextSmall }">
    <template v-if="isActive">
      <progress class="progress" :value="progressPercentage" max="100"></progress>
      <strong
        class="progress__number"
        :class="{ 'align-left': progressPercentage >= 80 }"
        :style="{ left: progressPercentage + '%' }"
      >{{progressPercentage}}% {{$t('progressBar.complete')}}</strong>
      <span class="progress__step">{{$t('progressBar.stepOf', [steps.length, totalSteps])}}</span>
      <button
        @click="skipStep"
        class="progress__skip button button--ghost"
        :class="{ disabled: nextStepDisabled }"
      >{{$t('progressBar.skip')}}</button>
      <button
        v-if="isActiveButton"
        @click="generatePlaylist"
        class="progress__generate button button--ghost"
        type="button"
        :style="{ opacity: generateOpacity }"
      >
        <span class="hidden--mobile">{{$t('progressBar.button')}}</span>
        <span class="visible--mobile">{{$t('progressBar.buttonMobile')}}</span>
      </button>
    </template>
  </div>
</template>

<script>
  import { TOTAL_STEPS, STEPS_COUNT_SHOW_PROGRESS } from '../config';
  import { EventBus } from '../event-bus';

  export default {
    name: 'progress-bar',
    props: {
      steps: {
        type: Array,
      },
    },
    data() {
      return {
        totalSteps: TOTAL_STEPS,
      };
    },
    computed: {
      progressPercentage() {
        return ((this.steps.length - 1) / TOTAL_STEPS) * 100;
      },
      nextStepDisabled() {
        return this.steps.length === TOTAL_STEPS;
      },
      isActive() {
        return this.steps.length > STEPS_COUNT_SHOW_PROGRESS;
      },
      isActiveButton() {
        return !this.steps.includes(TOTAL_STEPS);
      },
      isTextSmall() {
        return this.$i18n.locale === 'de';
      },
      generateOpacity() {
        return (this.progressPercentage * 1.2) / 100;
      },
    },
    methods: {
      generatePlaylist() {
        EventBus.$emit('generatePlaylist');
        EventBus.$emit('nextStep', 5, true);
      },
      skipStep() {
        if (this.nextStepDisabled) return;

        EventBus.$emit('nextStep', this.steps.length + 1, true);
      },
    },
  };
</script>

<style lang="scss" scoped>
@import "../scss/setup";

.progress {
  height: 20px;
  position: relative;
  border-radius: 20px;
  width: 100%;

  &__ {
    &number {
      position: absolute;
      color: $color__white;
      text-transform: uppercase;
      letter-spacing: $spacing__letter--medium;
      top: $spacing * 7;
      margin-left: -65px;

      &:after {
        content: "";
        position: absolute;
        height: $spacing * 3;
        width: 1px;
        background: $color__white;
        bottom: 25px;
        left: 64px;
      }

      &.align-left {
        margin-left: -140px;

        &:after {
          margin-left: 75px;
        }
      }
    }
    &wrapper {
      position: relative;
      height: 105px;
      padding-bottom: $spacing * 2;
      background: $color__black;
    }
    &generate {
      margin: ($spacing * 3) ($spacing * 3) 0;
      float: right;

      @media screen and (min-width: $media__table-portrait) {
        padding: 11px 20px 10px;
        font-size: 24px;
        line-height: 26px;
      }
    }
    &skip,
    &step {
      display: none;
    }
  }

  @media screen and (max-width: $media__table-portrait) {
    display: none;

    &__ {
      &wrapper {
        height: 60px;
        width: 100%;
        padding-bottom: 0;
        // background: $color__white;
        position: fixed;
        z-index: 2000;
        bottom: -60px;
        transition: 0.4s;

        &.active {
          bottom: 0;
          padding-bottom: 28px;
        }

        &.text-small {
          .progress {
            &__ {
              &step {
                font-size: 9px;
              }
              &skip,
              &generate {
                font-size: 10px;
              }
            }
          }
        }
      }
      &number {
        display: none;
      }
      &step {
        font-size: 11px;
        font-weight: bold;
        color: $color__white;
        text-transform: uppercase;
        letter-spacing: $spacing__letter--bigger;
      }
      &generate,
      &skip {
        // border: 1px solid $color__grey--dark;
        margin: 0;
        padding: 10px 0;
        font-size: 14px;

        &.disabled {
          opacity: 0.4;
        }
      }
      &generate {
        background: $color__grey--dark;
        color: $color__white;
      }
      &generate,
      &skip,
      &step {
        width: 29%;
        display: inline-block;
        margin: 10px 2% 0;
      }
    }
  }
}

progress[value]::-webkit-progress-bar {
  background-color: $color__grey--medium;
  border-radius: 0;
}

progress[value]::-webkit-progress-value {
  border-radius: 0;
  background-color: $color__white;
}
</style>
