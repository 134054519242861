<template>
  <section>
    <step-line :number="5"></step-line>
    <div class="row">
      <h2 class="ribbon-title">
        <span>{{$t('generate.title')}}</span>
      </h2>
      <p>{{$t('generate.paragraph')}}</p>
    </div>
    <div class="generate">
      <input class="input generate__input" type="text"
             v-model="playlistName" :placeholder="$t('generate.placeholder')">
      <button @click="generatePlaylist" type="button" class="button button-circle"
              :class="{active:generating}">
        {{$t('generate.button')}}
        <i class="circle3"></i>
      </button>
    </div>
  </section>
</template>

<script>
  import StepLine from './StepLine.vue'
  import { EventBus } from '../event-bus'

  export default {
    name: 'generate',
    components: { StepLine },
    props: {
      generating: {
        type: Boolean,
      },
    },
    data() {
      return {
        playlistName: '',
      }
    },
    watch: {
      playlistName(name) {
        EventBus.$emit('setName', name)
      },
    },
    methods: {
      generatePlaylist() {
        EventBus.$emit('generatePlaylist')
      },
    },
  }

</script>

<style lang="scss" scoped>
  @import "../scss/setup";

  .generate {
    max-width: $form__width;
    margin: 0 auto;

    &__ {
      &input {
        margin-top: $spacing * 3;
      }
    }
  }
</style>
