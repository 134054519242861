<template>
  <iframe :src="url" width="100%" height="596" frameborder="0"
          allowtransparency="true" allow="encrypted-media"></iframe>
</template>

<script>
  export default {
    name: 'playlist',
    props: {
      playlist: {
        type: Object,
      },
    },
    computed: {
      url() {
        return `https://open.spotify.com/embed?uri=${this.playlist.uri}`
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../scss/setup";
</style>
