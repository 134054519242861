<template>
  <section>
    <step-line :number="3"></step-line>
    <div class="row">
      <h2 class="ribbon-title ribbon-title--big">
        <span>{{$t('web.title')}}</span>
      </h2>
      <p>{{$t('web.paragraph')}}</p>
    </div>
    <div class="row">
      <nav class="tabs visible--mobile">
        <div v-for="tab in tabs" :key="tab"
             class="tabs__item" :class="{active: activeTab === tab}"
             @click="activeTab = tab">
          {{$t('web.' + tab+ '.tab')}}
        </div>
      </nav>
      <web v-for="tab in tabs" :key="tab"
           class="web" :class="{ hidden: activeTab !== tab }"
           :tab="tab" :artists="artists[tab]"
           :selectedClusters="selectedClusters">
      </web>
    </div>
    <div class="row">
      <button @click="nextStep" type="button"
              class="button button--small"
              :class="{ 'button--ghost': !showNext }">
        {{ showNext ? $t('web.next') : $t('web.skip')}}
      </button>
    </div>
  </section>
</template>

<script>
  import StepLine from './StepLine.vue'
  import Web from './Web.vue'
  import { EventBus } from '../event-bus'
  import incluencesData from '../data/influences.json'
  import membersData from '../data/member.json'
  import { shuffleArray } from '../utils/shuffle'
  import { WEB_INFLUENCE } from '../config'

  const TABS = ['member', 'influence']

  export default {
    name: 'clusters',
    components: { StepLine, Web },
    props: {
      selectedClusters: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        activeTab: TABS[0],
        tabs: TABS,
      }
    },
    computed: {
      artists() {
        return {
          member: membersData,
          influence: shuffleArray(incluencesData).splice(0, WEB_INFLUENCE),
        }
      },
      showNext() {
        return this.selectedClusters.length > 0
      },
    },
    methods: {
      nextStep() {
        EventBus.$emit('nextStep', 4, !this.showNext)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../scss/setup";

  .web {
    @media screen and (max-width: $media__mobile) {
      &.hidden {
        transform: translateX(100%);
        position: absolute;
      }
    }
  }

  .tabs {
    background: rgba($color: #040404, $alpha: .5);
    margin-bottom: $spacing * 3;
    font-size: 18px;
    font-weight: bold;

    &__ {
      &item {
        display: inline-block;
        width: 50%;
        height: 68px;
        line-height: 68px;
        cursor: pointer;
        color: #999999;

        &.active {
          color: $color__white;
          border-bottom: 2px solid $color__white;
        }
      }
    }
  }
</style>
