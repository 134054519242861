import axios from 'axios'
import { ARTIST_LIMIT, LOCALE_FALLBACK } from '../config'

export function searchArtist(artist) {
  return axios
    .get(`/api/search/artists?query=${artist}&limit=${ARTIST_LIMIT}`)
    .then((result) => result.data)
}

export function generatePlaylist(clusters, mood, name, artistsIds, token = null) {
  const url = token ? '/api/playlists/generator' : '/api/playlists/generator/noauth'
  const config = {}
  const body = {
    clusters,
    mood_name: mood,
    name,
    seed_artists: artistsIds,
  }

  if (token) {
    config.headers = { Authorization: `Bearer ${token}` }
  }

  return axios
    .post(url, body, config)
    .then((result) => result.data)
}

export function getStats(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  return axios
    .get('/api/stats/me/guns_or_roses', config)
    .then((result) => result.data)
}

export function getProfile(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  return axios
    .get('/api/me', config)
    .then((result) => result.data)
}

export function logout(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  return axios
    .post('/api/me/optout', {}, config)
    .then((result) => result.data)
}

export function getLocale() {
  return axios
    .get('/api/geo/current')
    .then((result) => result.data)
    .catch(() => ({ country_code: LOCALE_FALLBACK }))
}

export function postNewsletter(body) {
  return axios
    .post('https://www.playlist-generator.com/api/newsletters/subscriptions', body)
    .then((result) => result)
}
