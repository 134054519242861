/* eslint no-cond-assign: 'off' */

export function shuffleArray(array) {
  let i = array.length
  let j = 0
  let temp

  while (i -= 1) {
    j = Math.floor(Math.random() * (i + 1))

    temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}
